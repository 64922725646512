import React, {useState} from 'react';
import Navbar from "./Navbar";
import { RiErrorWarningFill } from "react-icons/ri";
import { FaFile } from "react-icons/fa";
import { HiIdentification } from "react-icons/hi2";
// import { FaInstagram } from "react-icons/fa6";
// import { TfiTwitter } from "react-icons/tfi";
// import { MdOutlineEmail } from "react-icons/md";
// import { FaTelegramPlane } from "react-icons/fa";
import Review from "./hooks/Reviews";
import Footer from "./hooks/Footer";



const Home = () => {

    const Accordion = ({ title, content }) => {
        const [isOpen, setIsOpen] = useState(false);
      
        return (
          <div className="border rounded shadow mb-4">
            <div
              className="flex justify-between items-center p-4 cursor-pointer bg-gray-200"
              onClick={() => setIsOpen(!isOpen)}
            >
              <h2 className="text-lg font-semibold">{title}</h2>
              <svg
                className={`w-6 h-6 transition-transform duration-300 transform text-[#162e51] ${
                  isOpen ? 'rotate-45' : ''
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={isOpen ? 'M6 18L18 6' : 'M6 18L18 6M6 6l12 12'}
                />
              </svg>
            </div>
            {isOpen && (
              <div className="p-4 bg-white">
                <p>{content}</p> <br />
              </div>
            )}
          </div>
        );
      };
    
  return (
    <>
    <Navbar/>
    <div className='home__img'>
        <div className='mx-auto md:max-w-7xl overflow-hidden  pt-[10%]'>
            <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-1'>
                <div className='bg-gren-600 h-fit  z-10'>
                    <div>
                        <p className='xl:text-[48px] text-[35px] text-white font-[400] font-cormorant'>Specializing in Scam Victim Restitution and Financial Restoration</p>
                    </div>

                    <div className='pt-5'>
                        <p className='text-white font-[400] font-sans  text-[17px]'>
                        With a comprehensive ban on new noncompetes, Americans could see an increase in wages, new business formation, reduced health care costs and more.
                        </p>
                    </div>

                   <a href="/contact">
                    <div className='pt-5'>
                        <button className='bg-[#055cb4] hover:bg-[#1d3557] transition-colors p-4 text-white mb-10'>
                            Report Scams Now
                        </button>
                    </div>
                   </a>
                </div>
                <div className='bg-gree-600 h-fit'>

                </div>
            </div>
        </div>
    </div>
    <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div className='pt-5 ml-5'>
                <p className='font-cormorant text-[32px] font-[400]'>Take Action</p>
                <div className='action__borderx'></div>
            </div>
        <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 sm:grid-cols-1 md:grid-cols-1 pt-5'>
            <div className='relative h-fit p-3 cursor-pointer hover:bg-[#345079] hover:text-white duration-300 ease-in-out'>
                <div className='flex gap-2 hover:text-white'>
                    <RiErrorWarningFill size={24} color='#1d3557'/> 
                    <span className='text-[17px] font-sans font-[500]'>
                         Report fraud
                     </span>
                </div>
                <div className='absolute inset-x-0 bottom-0 h-[0.1rem] bg-gray-400 transition-all duration-300'></div>
            </div>
            
            <div className='bg-reen-500  relative h-fit p-3 cursor-pointer hover:bg-[#345079] hover:text-white duration-300 ease-in-out'>
                <div className='flex gap-2'>
                    <FaFile size={24}  color='#1d3557'/> 
                    <span className='text-[17px] font-sans font-[500]'>
                        File an antitrust complaint
                    </span>
                </div>
                <div className='absolute inset-x-0 bottom-0 h-[0.1rem] bg-gray-400 transition-all duration-300'></div>
            </div>
            <div className='relative h-fit p-3 cursor-pointer hover:bg-[#345079] hover:text-white duration-300 ease-in-out'>
                <div className='flex gap-2'>
                    <HiIdentification size={24}  color='#1d3557'/> 
                    <span className='text-[17px] font-sans font-[500]'>
                       Report identity theft
                    </span>
                </div>
                <div className='absolute inset-x-0 bottom-0 h-[0.1rem] bg-gray-400 transition-all duration-300'></div>
            </div>
        </div>
    </div>

    <div className='bg-[#f6faff] '>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
        <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 sm:grid-cols-1 md:grid-cols-2 pt-5 pb-10'>
            <div className='bg-green- h-full z-10 overflow-hidden'>
                <div className='bg-white h-fit px-5 pt-5 pb-5 transition-transform transform hover:scale-105 cursor-pointer'>
                 <div>
                  <img src="https://www.ftc.gov/system/files/styles/wide_standard_sm/private/ftc_gov/images/child-laptop-small-istock-1090162870.jpg?h=c9f93661&itok=-585TsMM" alt="" />
                 </div>
                 <div className=''>
                    <p className='font-[400] xl:text-[30px] text-[24px] pt-2 font-cormorant text-[#1d3557] copper__txt'>COPPA: What You Should Know</p>
                 </div>

                 <div>
                    <p className='text-[17px] font-sans font-[400]'>
                    The Children's Online Privacy Protection Act (also known as COPPA) allows parents to control what information websites can collect from their kids.
                    </p>
                 </div>
                </div>
            </div>
            <div className='bg-green- h-full z-10 overflow-hidden'>
                <div className='bg-white h-fit px-5 pt-5 pb-5 transition-transform transform hover:scale-105 cursor-pointer'>
                 <div>
                  <img src="https://www.ftc.gov/system/files/styles/wide_standard_sm/private/ftc_gov/images/opensign-business-smaller-istock-1321891128.jpg?h=6b7011a3&itok=ozGjjyrd" alt="" />
                 </div>
                 <div className=''>
                    <p className='font-[400] xl:text-[30px] text-[24px] pt-2 font-cormorant text-[#1d3557] copper__txt custom'>Protect Your Small Business
                    </p>
                 </div>

                 <div>
                    <p className='text-[17px] font-sans font-[400] xl:pt-5 pt-0'>
                    Learn ways to prevent your business from falling victim to common scams involving impersonation, tech support, fake invoices and more.
                    </p>
                 </div>
                </div>
            </div>
            <div className='bg-green- h-full z-10 overflow-hidden'>
                <div className='bg-white h-fit px-5 pt-5 pb-5 transition-transform transform hover:scale-105 cursor-pointer'>
                 <div>
                  <img src="https://www.ftc.gov/system/files/styles/wide_standard_sm/private/ftc_gov/images/creditreport-smaller-istock-187135395.jpg?h=f7d9296c&itok=LFGrbjCS" alt="" />
                 </div>
                 <div className=''>
                    <p className='font-[400] xl:text-[30px] text-[24px] pt-2 font-cormorant text-[#1d3557] copper__txt'>Fair Credit Reporting Act</p>
                 </div>

                 <div>
                    <p className='text-[17px] font-sans font-[400]'>
                    Do you use credit reports to make eligibility decisions about consumers? Learn about the Fair Credit Reporting Act and how to responsibly use, report and dispose of information in those reports.
                    </p>
                 </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div className='tower__img'>
        <div className='mx-auto md:max-w-7xl overflow-hidden '>
            <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-1 pt-5 pb-10'>
                <div className='bg-gren-600 h-fit z-10 mt-5 boder__end'>
                    <p className='xl:text-[34px] text-[30px]  font-cormorant font-[400] text-white text-center'>Sign up for quick money recovery.</p>

                    
                </div>
                <div className='bg-green-60 h-fit z-10 mt-5'> 
                <a href="https://phantomassist.org/signup/" target='_blank' rel='noreferrer'>
                    <div className='flex justify-center pt-5 xl:pt-0'>
                        <button className='bg-[#162e51] text-white p-5 w-[30%]'>Register</button>
                    </div>
                </a>

                    {/* <div className='flex justify-center gap-3'>
                        <div>
                         <FaInstagram size={24} color='white'/>
                        </div>

                        <div>
                            <TfiTwitter  size={24} color='white'/>
                        </div>
                        <div>
                            <MdOutlineEmail  size={24} color='white'/>
                        </div>
                        <div>
                            <FaTelegramPlane  size={24} color='white'/>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>

    <div className='mx-auto md:max-w-7xl overflow-hidden'>
        <div className='pt-5 ml-5'>
            <p className='font-cormorant text-[32px] font-[400]'>Top Services</p>
            <div className='action__borderx'></div>
        </div>


        <div className='grid xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 sm:grid-cols-1 md:grid-cols-2 pt-5 pb-10'>
            <div className='bg-green- h-full z-10 overflow-hidden shadow__md rounded-md'>
                <div className='bg-white h-fit px-5 pt-5 pb-5 transition-transform transform hover:scale-105 cursor-pointer '>
                 <div>
                  <img src="https://www.edwardjones.ca/sites/default/files/acquiadam/2022-07/iStock-1323758548-1810x1110.jpg" alt="" />
                 </div>
                 <div className=''>
                    <p className='font-[400] xl:text-[30px] text-[24px] pt-2 font-cormorant text-[#1d3557] copper__txt custom'>Crypto Currency Recovery
                    </p>
                 </div>

                 <div>
                    <p className='text-[17px] font-sans font-[400] xl:pt-5 pt-0'>
                    Lost funds or cryptocurrency? Phantom Assist is here to help! Our expert team specializes in recovering inaccessible wallets, ensuring you regain access with dedicated professionalism.
                    </p>
                 </div>

                 <a href="/crypto">
                    <div className='mt-5'>
                        <button className='bg-[#162e51] p-3 text-white learn__deco'>Learn more</button>
                    </div>
                 </a>
                </div>
                
            </div>
            <div className='bg-green- h-full z-10 overflow-hidden shadow__md rounded-md'>
                <div className='bg-white h-fit px-5 pt-5 pb-5 transition-transform transform hover:scale-105 cursor-pointer '>
                 <div>
                  <img src="https://cyberscoop.com/wp-content/uploads/sites/3/2023/07/GettyImages-1258231373.jpg" alt="" />
                 </div>
                 <div className=''>
                    <p className='font-[400] xl:text-[30px] text-[24px] pt-2 font-cormorant text-[#1d3557] copper__txt custom'>Romance Scam Recovery
                    </p>
                 </div>

                 <div>
                    <p className='text-[17px] font-sans font-[400] xl:pt-5 pt-0'>
                    At Phantom Assist, we specialize in assisting victims of romance scams. Trust our experts to restore your confidence and recover your lost assets swiftly and effectively.
                    </p>
                 </div>

                 <a href="/romance">
                    <div className='mt-5'>
                        <button className='bg-[#162e51] p-3 text-white learn__deco'>Learn more</button>
                    </div>
                 </a>
                </div>
                
            </div>
            <div className='bg-green- h-full z-10 overflow-hidden shadow__md rounded-md'>
                <div className='bg-white h-fit px-5 pt-5 pb-5 transition-transform transform hover:scale-105 cursor-pointer '>
                 <div>
                  <img src="https://blog.cfi.co/wp-content/uploads/2020/08/commercial-bank-vs.-investment-bank.jpg" alt="" />
                 </div>
                 <div className=''>
                    <p className='font-[400] xl:text-[30px] text-[24px] pt-2 font-cormorant text-[#1d3557] copper__txt custom'>Fake Investment Recovery
                    </p>
                 </div>

                 <div>
                    <p className='text-[17px] font-sans font-[400] xl:pt-5 pt-0'>
                    Regaining from a fake investment? Phantom Assist swiftly reclaims your lost assets from fraudulent schemes, ensuring your financial security with expert guidance.
                    </p>
                 </div>
                    <a href="/fakeinvestment">
                        <div className='mt-5'>
                            <button className='bg-[#162e51] p-3 text-white learn__deco'>Learn more</button>
                        </div>
                    </a>
                 
                </div>
                
            </div>
            <div className='bg-green- h-full z-10 overflow-hidden shadow__md rounded-md'>
                <div className='bg-white h-fit px-5 pt-5 pb-5 transition-transform transform hover:scale-105 cursor-pointer '>
                 <div>
                  <img src="https://nrccollections.ca/ClientFiles/amp/slide2.jpg" alt="" />
                 </div>
                 <div className=''>
                    <p className='font-[400] xl:text-[30px] text-[24px] pt-2 font-cormorant text-[#1d3557] copper__txt custom'>Reclaim Funds from Fake Recovery Agency
                    </p>
                 </div>

                 <div>
                    <p className='text-[17px] font-sans font-[400] xl:pt-5 pt-0'>
                    Dedicated to recovering funds from scammers, our expert team ensures the swift and secure return of your money with relentless determination. Your peace of mind is our priority.
                    </p>
                 </div>

                <a href="/reclaimfunds">
                    <div className='mt-5'>
                        <button className='bg-[#162e51] p-3 text-white learn__deco'>Learn more</button>
                    </div>
                </a>
                
                </div>
                
            </div>
            <div className='bg-green- h-full z-10 overflow-hidden shadow__md rounded-md'>
                <div className='bg-white h-fit px-5 pt-5 pb-5 transition-transform transform hover:scale-105 cursor-pointer '>
                 <div>
                  <img src="https://www.shutterstock.com/image-photo/concept-extortion-blackmail-man-hoodie-600nw-2010567164.jpg" alt="" />
                 </div>
                 <div className=''>
                    <p className='font-[400] xl:text-[30px] text-[24px] pt-2 font-cormorant text-[#1d3557] copper__txt custom'>Help From Blackmail & Extortion
                    </p>
                 </div>

                 <div>
                    <p className='text-[17px] font-sans font-[400] xl:pt-5 pt-0'>
                    Empowering victims of online blackmail, sextortion, and threats, we provide unwavering support and expert guidance. 
                    </p>
                 </div>

                 <a href="/blackmail">
                 <div className='mt-5'>
                    <button className='bg-[#162e51] p-3 text-white learn__deco'>Learn more</button>
                </div>
                 </a>
                </div>
                
            </div>
            <div className='bg-green- h-full z-10 overflow-hidden shadow__md rounded-md'>
                <div className='bg-white h-fit px-5 pt-5 pb-5 transition-transform transform hover:scale-105 cursor-pointer '>
                 <div>
                  <img src="https://t3.ftcdn.net/jpg/03/89/32/80/360_F_389328016_ak3iUrk15slWfEZdYL96O6eKTUyImDeC.jpg" alt="" />
                 </div>
                 <div className=''>
                    <p className='font-[400] xl:text-[30px] text-[24px] pt-2 font-cormorant text-[#1d3557] copper__txt custom'>Social Media Spy & Recovery
                    </p>
                 </div>

                 <div>
                    <p className='text-[17px] font-sans font-[400] xl:pt-5 pt-0'>
                    Empowering your digital connections, we ensure swift recovery of your social accounts. Trust our expertise and dedication to restore your access promptly and efficiently.
                    </p>
                 </div>

                 <a href="/social">
                 <div className='mt-5'>
                    <button className='bg-[#162e51] p-3 text-white learn__deco'>Learn more</button>
                </div>
                </a>
                </div>
                
            </div>
            
        </div>
    </div>

    <div className='bg-[#f0f0f0] pb-5'>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div className='pt-5 ml-5'>
                <p className='font-cormorant text-[32px] font-[400]'>Top Testimonies</p>
                <div className='action__borderx'></div>
            </div>


            <div>
                <Review/>
            </div>
        </div>
    </div>

    <div className='mx-auto overflow-hidden md:max-w-7xl'>
    <div className='pt-5 ml-5'>
                <p className='font-cormorant text-[32px] font-[400]'>F. A. Q.</p>
                <div className='action__borderx'></div>
            </div>
                        <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2  xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4 pb-10 pt-[3%]'>
                            <div className='bg-green-00 h-fit'>
                                <Accordion
                                    title="100% Refund Policy"
                                    content="Phantomassist is our unwavering commitment to delivering superior results and 
                                    meeting the high expectations of our valued clients. While we have full confidence in our ability 
                                    to accomplish the given tasks, we acknowledge that unforeseen circumstances may occasionally arise, 
                                    potentially impacting our ability to honor our commitments. In such cases, if we find ourselves 
                                    unable to fulfill a task, we stand by our commitment to customer satisfaction by offering a refund, 
                                    ensuring trust in our brand
                                    At the heart of our operations lie principles of transparency and fairness in all our dealings. Our 
                                    terms and conditions are thoughtfully designed to provide clarity and protection for both our clients 
                                    and our team. Our dedicated focus remains on delivering exceptional service and value to our clients. 
                                    Continuously, we strive to improve our processes, consistently surpassing expectations.
                                    "
                                />
                                <Accordion
                                   title="How does your recovery firm operate?"
                                    content="Our firm, specializing in scam recovery, is steadfast in its commitment to aiding victims of 
                                    fraudulent schemes throughout the intricate process of reclaiming their lost funds. Employing a 
                                    multi-faceted approach, we merge specialized expertise, cutting-edge technology, and legal resources 
                                    to offer the utmost support to our clients.
                                    Central to our operations is a team comprising seasoned professionals with a niche focus on the complex 
                                    realm of scam recovery. These adept experts harness a blend of investigative methodologies and financial
                                     expertise to meticulously unravel the intricate web of transactions associated with the scam. 
                                     Through exhaustive tracking of the financial trail, we meticulously pinpoint and segregate the assets 
                                     rightfully belonging to you.
                                    "
                                />
                            </div>
                            <div className='bg-green600 h-fit'>
                                <Accordion 
                                    title="Can I Trust This Firm?" 
                                    content="We acknowledge the pivotal importance of trust and authority when it comes to our clients 
                                    feeling secure and confident in sharing sensitive financial information with our team. We are fully 
                                    aware that the decision to entrust us with such critical information necessitates careful 
                                    consideration, a responsibility we take with the utmost seriousness.
                                    We firmly believe that trust is not simply given, but earned through a continuous display of 
                                    consistent and dependable performance. Our commitment lies in surpassing our clients' expectations 
                                    in every facet of our work, striving to foster an environment of reliability and excellence."
                                    
                                    />
                                <Accordion 
                                    title="How Long dose the process take?" 
                                    content="The process of recovering lost Cryptocurrency spans a variable timeline, fluctuating between 
                                    durations as short as one hour and potentially extending to two months or more. This duration may seem 
                                    extensive due to our comprehensive initial search conducted within the first week of onboarding a 
                                    client. After this exhaustive search, we retain the encrypted private backup and employ password 
                                    guesses to perpetuate testing, identifying new recovery vectors, and enhancing our hardware 
                                    capabilities.
                                    Respecting the privacy and security of our clients, we maintain client information unless expressly 
                                    requested for deletion. Our commitment to safeguarding client privacy remains unwavering, adhering 
                                    to strict protocols in data management
                                    " 
                                />
                            </div>
                        </div>
         </div>

        <div>
            <Footer/>
        </div>
    </>
  )
}

export default Home