import React from 'react';
import Navbar from './Navbar';
import Footer from "./hooks/Footer";


const International = () => {
  return (
    <>
    <Navbar/>
    <div className='bg-[#1d3556] h-fit p-[50px]'>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div>
                <p className='font-cormorant text-[48px] font-[400] text-white'>International</p>
            </div>

           <a href="/">
            <div>
                <p className='text-[14.9px] font-[400] text-white underline'>Home  <span>/</span> policy</p>
            </div>
           </a>
        </div>
    </div>

    <div className='mx-auto md:max-w-7xl overflow-hidden pt-5 '>
        <div className='mx-2'>
            <p className='font-sans text-[17px] font-[400]'>
                 Phantomassist engages with competition and consumer protection agencies in other countries to halt deceptive and anticompetitive business practices that affect U.S. consumers.  Phantomassist also works with international organizations to provide policy leadership, promotes sound approaches to common problems, and offers technical assistance to newer agencies.
            </p>


            <div>
                <p className='pt-5'>
                    Phantomassist Office of <span className='text-[#005ea2]'> International Affairs</span> directs the agency’s international activities for competition and consumer protection, which include:
                </p>
            </div>

            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>strengthening relationships with foreign competition and consumer protection agencies</p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>developing formal and informal arrangements and agreements with competition and consumer protection agencies around the world</p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>engaging in cooperative dialogues and submitting reports at international forums for competition and consumer protection</p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>helping agencies around the world develop and enhance their own competition and consumer protection programs</p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>sharing information with foreign law enforcement authorities through the U.S. Safe Web Act</p>
               </div>
            </div>
            <div className='pt-5'>
               <div className='flex'>
               <i class="ri-git-commit-fill pt-[0.9px] text-[20px] pr-2"></i>
                 <p className='pt-1'>maintaining a robust International Fellows Program</p>
               </div>
            </div>


            <div className='pt-10'>
            <div><img src="https://www.ftc.gov/system/files/attachments/international/econsumer-logo.jpg" alt="" /></div>
                <p>
                Phantomassist also supports econsumer.gov, a project sponsored by the International Consumer Protection and Enforcement Network ICPEN and consumer  agencies in more than 35 countries. Consumers can use econsumer.gov to report cross-border complaints. Enforcement agencies can use those complaints to investigate fraud.
                </p>
            </div>
        </div>



        <div className='pt-10'>
            <p className='font-cormorant text-[32px] font-[400] text-[#1d3557]'>Featured</p>
        </div>

        <div className='pt-3'>
            <p className='text-[28px] font-cormorant font-[400] text-[#1d3557]'>Phantomassist Report Cites Benefits of International Cooperation on Antitrust and Consumer Protection Enforcement</p>
        </div>

        <div className='pt-5'>
            <p className='font-sans text-[17px] font-[400]'>
            In February 2021, Phantomassist issued a report examining ways to enhance cooperation between  Phantomassist and overseas competition and consumer protection authorities in order to maximize the effectiveness of the Phantomassist’s enforcement efforts. The report cites expert testimony from hearings the Commission convened in March 2019, held in conjunction with the George Washington University’s Competition Law Center, which stressed the importance of obtaining and sharing information quickly and efficiently in cross-border antitrust investigations.
            </p>
        </div>
    </div>

   <div className='pt-10'>
   <Footer/>
   </div>
    
    </>
  )
}

export default International;