import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { BsStarFill } from "react-icons/bs";


const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="carouselx h-fit">
       

      <Slider {...settings} className='dragging mt-10' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHvoMnKEXoMV8jsN7IzYSa8AkZh9doAiFmGA&usqp=CAU" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Evelyn Bennett</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    In my quest to invest my pensions wisely and generate more income, I made an unfortunate decision to invest $75,000, 
                    my entire pension, in a platform promising returns of $119,000. Falling victim to a scam was devastating. However, 
                    my luck changed when I came across recommendations for Phantomassist online. His expertise and team not only restored 
                    my funds but also dismantled the fake platform. My gratitude to Phantomassist knows no bounds for bringing justice to 
                    the digital world.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 '>
                <p className='text-[#b0b0b0]'>Texas • USA </p>
            </div>
            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'></p>
            </div>
                    
           </div>
        </div>

        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://media.istockphoto.com/id/1284873957/photo/happy-to-have-a-place-i-can-call-home.jpg?s=612x612&w=0&k=20&c=eAzoPyvWYU19oxME47GOgVW_Kwm5QBfhn5pbKPPvsSI=" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Jerry Stewart</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-10'>
                    For years, I was in an online relationship with a man named Stephan on Instagram. He promised marriage and, out of love, I 
                    lent him $564,000. He assured me he'd repay, but he abruptly blocked me. Fortunately, I found Phantomassist 
                    through a recommendation. With minimal effort, I provided them the scammer's details, and they swiftly recovered my 
                    money. I'm immensely grateful to them.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'> Dakota • USA</p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://us.123rf.com/450wm/dubova/dubova1209/dubova120900008/15019451-face-portrait-d-une-belle-femme-de-35-ans.jpg?ver=6" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Fanny Onil</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    I found myself in a grave predicament when I unknowingly shared my 12-word phrase with an online scammer, 
                    who swiftly drained my Trust Wallet account of $200,000 worth of Bitcoin. The weight of the loss led me to 
                    the brink of despair, but a ray of hope emerged when I confided in Phantomassist. Remarkably, in under a week, 
                    he orchestrated the retrieval of my funds. It's difficult to express the depth of my gratitude – Phantomassist 
                    gave me a second chance at life, and I am forever indebted to him.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'>Oregon • USA </p>
            </div>
                    
           </div>
        </div>


        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://i.pinimg.com/originals/d9/d6/e4/d9d6e40dd3959af46f05e7873de88382.jpg" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Jasper Finnegan</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    I was scammed $67,000 worth of bitcoin by a romance scammer, and he was also threatening to blackmail me and 
                    leak my secrets if I don't give him more money. I was tired of paying him in bitcoin because it's way too high 
                    for me. However, a friend recommended me to Phantomassist, who helped me restore my stolen bitcoin, kept my 
                    secret safe, and restored my peace of mind.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-5 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-5'>
                <p className='text-[#b0b0b0]'> Quebec • Canada</p>
            </div>
                    
           </div>
        </div>
        <div className='slide-wrapper '>
           <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
           <div className="bg-[reen] h-fit pt- pl-5 pt-[5%]">
                        <div className='flex '>
                            <div className='pt-1'>
                            <Avatar className="!w-[50px] !h-[50px] ">
                                <img src="https://media.istockphoto.com/id/635850530/photo/mid-adult-businesswoman-smiling-towards-the-camera.jpg?s=612x612&w=0&k=20&c=zVlu9Msc1Ndl8ufAJtSeY0zrNXu_eTuD5-o_AyLUkuI=" alt=""/>
                            </Avatar>
                            </div>

                            <div className='pl-3 pt-3'>
                                <p className='font-bold'>Penny Blossom</p>
                            </div>
                        </div>
                    </div> 
                <div className='pl-2 pr-1'>
                    <p className='pt-5 '>
                    For months, I have been investing in this fake platform called Quick Crypto Invest. I've been diligently saving 
                    for months, and my balance had reached over £122,000+. I hoped for my money to grow, unaware that the balance 
                    displayed on the website was entirely fabricated. I thought I had lost it all, but my perception changed when 
                    I came across recommendations about Phantomassist. He aided me in restoring my funds, dismantling the fraudulent 
                    platform. We truly need more individuals like Phantomassist in this world.
                    </p>
                </div>
                
            <div className='flex pl-2 pt-2 pb-'>
            <BsStarFill className="text-[#162e51"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            <BsStarFill className="text-[#162e51]"/>
            </div>

            <div className='pl-2 pb-2'>
                <p className='text-[#b0b0b0]'> Victoria • Australia </p>
            </div>
                    
           </div>
        </div>
       
      
      </Slider>

      <div className='text-center pt-10 pb-10'>
        <p>swipe to see more.</p>
      </div>
    </div>
  );
}

export default Review;