import React from 'react';
import {  FaInstagram,  FaWhatsapp  } from 'react-icons/fa';
import { MdOutlineEmail } from "react-icons/md";

const Footer = () => {
  return (
    <>
    <div className='bg-[#2F2F2F] w-full h-[3rem]'></div>
     <div className='bg-[#444444] h-fit overflow-hidden'>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div className='grid mt-10 xl:mt-0 ml-3 mr-3 grid-cols-1  lg:grid-cols-2 xl:grid-cols-4 sm:grid-cols-1 md:grid-cols-2 gap-10 pb-10 pt-[5%] overflow-hidden'>
                <div className='bg-geen-600 h-fit'>
                    <div className='flex justify-center'>
                      <p className='font-bold text-white text-2xl'>Phantomassist</p>
                    </div>
                    <div className="flex space-x-4 bggray-800 p-4 justify-center">
                        <a href="https://wa.me/+1(469)985‑5403" target='_blank' rel='noreferrer' className="text-[#444444] hover:text-[#215EA7]">
                            <div className="bg-[#ebebeb] rounded-full p-2">
                            <FaWhatsapp size={24}/>
                            </div>
                        </a>
                        <a href="mailto:Phantomassistance@gmail.com"  target='_blank' rel='noreferrer' className="text-[#444444] hover:text-[#215EA7]">
                            <div className="bg-[#ebebeb] rounded-full p-2">
                                <MdOutlineEmail size={24}/>
                            </div>
                         </a>
                        <a href="https://Instagram.com/Phantomassist" target='_blank' rel='noreferrer' className="text-[#444444] hover:text-[#215EA7]">
                            <div className="bg-[#ebebeb] rounded-full p-2">
                                <FaInstagram size={24} />
                            </div>
                        </a>
                        
                    </div>
                    <div className='flex justify-center font-semibold'> 
                        <a href="/contact">
                            <p className='text-[#fefefe]'>Contact Phantomassist</p>
                        </a>
                    </div>
                    <div className='flex justify-center font-semibold'> 
                        <a href="/policy">
                            <p className='text-[#fefefe]'>Policies</p>
                        </a>
                    </div>
                    {/* <div className='flex justify-center font-semibold'> 
                        <p className='text-[#fefefe]'>Accessibility</p>
                    </div> */}
                </div>
                <div className='bg-gren-600 h-fit '>
                    <div className='flex justify-center'>
                        <p className='font-bold text-2xl text-[#bcbcbc]'>Policy</p>
                    </div>
                    <div className='flex justify-center font-semibold'> 
                        <p className='text-[#fefefe] py-1'>Testimony</p>
                    </div>
                    <div className='flex justify-center font-semibold'> 
                        <a href="/international">
                            <p className='text-[#fefefe] py-1'>International</p>
                        </a>
                    </div>
                    <div className='flex justify-center font-semibold'> 
                        <a href="/policy">
                            <p className='text-[#fefefe] py-1'>Recovery Policy</p>
                        </a>
                    </div>
                    
                </div>
                <div className='bg-gren-600 h-fit '>
                    <div className='flex justify-center'>
                        <p className='font-bold text-2xl text-[#bcbcbc]'>SERVICES</p>
                    </div>
                    <div className='flex justify-center font-semibold'> 
                        <a href="/romance">
                            <p className='text-[#fefefe] py-1'>Romance Scam Recovery</p>
                        </a>
                    </div>
                    <div className='flex justify-center font-semibold'> 
                       <a href="/crypto">
                        <p className='text-[#fefefe] py-1'>Crypto Currency Recovery</p>
                       </a>
                    </div>
                    <div className='flex justify-center font-semibold'> 
                        <a href="//fakeinvestment">
                            <p className='text-[#fefefe] py-1'>Fake Investment Recovery</p>
                        </a>
                    </div>
                    <div className='flex justify-center font-semibold'> 
                       <a href="/reclaimfunds">
                            <p className='text-[#fefefe] py-1 text-center'>Reclaim Funds from Fake Recovery Agency</p>
                       </a>
                    </div>
                </div>
                <div className='bg-gren-600 h-fit '>
                    <div className='flex justify-center'>
                        <p className='font-bold text-2xl text-[#bcbcbc] uppercase'>Contact Us</p>
                    </div>
                    <div className='flex justify-center font-semibold'> 
                       <a href="/contact">
                        <p className='text-[#fefefe] py-1'>Report Fraud</p>
                       </a>
                    </div>
                    <div className='flex justify-center font-semibold'> 
                       <a href="https://phantomassist.org/signup/login"  target='_blank' rel="noreferrer">
                             <p className='text-[#fefefe] py-1'>Sign up</p>
                       </a>
                    </div>
                    <div className='flex justify-center font-semibold'> 
                      <a href="mailto:Phantomassistance@gmail.com">
                        <p className='text-[#fefefe] py-1'>Email Us</p>
                      </a>
                    </div>
                    
                </div>
            </div>
        </div>

        <div className='bg-[#3b3b3b] w-full h-fit overflow-hidden'>
            <p className='underline flex justify-center text-center pt-2 pb-2 font-bold text-[#b9bac4]'>
                © 2013 Phantomassist   | All Rights Reserved
            </p>
        </div>
    </div>
 </>

  )
}

export default Footer;